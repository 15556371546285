import { useConfig } from '@components/ConfigProvider';
import { getVehicleContacts } from '@cv/portal-idm-lib/contact';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useCookie from './useCookie';
import { TokenTypes } from '@api/services/StoreService';
import useVehicle from './useVehicle';
import { formatContactArray } from '@api/formatters';
import { ContactTypes } from '@cv/portal-idm-lib/contact/enums';

const useContacts = (type: ContactTypes) => {
  const accessToken = useCookie(TokenTypes.ACCESS_TOKEN);
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const config = useConfig();
  const environment = config.getEnvironmentEnum();
  const { data: vehicle } = useVehicle();

  const vehicleId = vehicle?.vehicleId;

  return useQuery({
    enabled: !!vehicleId,
    queryKey: ['contacts'],
    queryFn: async () => {
      const payload = {
        environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
        pathParams: {
          vehicleId: vehicle?.vehicleId,
        },
      };

      const { data } = await getVehicleContacts(payload);
      return data;
    },
    select: (data) => formatContactArray(data, { type }),
    placeholderData: { result: [] },
  });
};

export default useContacts;
